section.cta {
  /* default styles */
  @apply pt-20
         pb-16;
  /* responsive styles */
  @apply lg:pt-32
         lg:pb-28;

  .idea {
    @apply relative;
    &:before {
      background: url(../img/idea.svg) no-repeat center center;
      @apply content-[""]
             block
             lg:absolute
             lg:top-[-120px]
             lg:left-[-160px]
             w-[170px]
             h-[160px]
             m-auto
             translate-x-[-45px]
             translate-y-[-20px]
             lg:translate-x-0
             lg:translate-y-0;
    }
  }

  h1 {
    /* default styles */
    @apply mb-1
           font-bold
           uppercase;

    /* responsive styles */
    @apply text-4xl;
    @apply md:text-6xl;
           
  }

  p {
    /* default styles */
    @apply font-light
           leading-tight;

    /* responsive styles */
    @apply text-xl;
    @apply md:text-3xl;
  }
}