.responsive-helper {
  span {
    @apply flex
           font-medium
           text-lg
           uppercase
           w-[45px]
           h-[45px]
           bg-brandPrimary
           justify-center
           items-center
           fixed
           bottom-0
           right-0
           invisible;

    &._sm { @apply sm:visible; }
    &._md { @apply md:visible; }
    &._lg { @apply lg:visible; }
    &._xl { @apply xl:visible; }
    &._2xl { @apply 2xl:visible; }
  }
}