section.not-found {
  /* default styles */
  height: calc(100vh - 4rem);
  
  @apply flex
         p-0
         m-0
         justify-center
         items-center;

  &.hero.hero-main {
       @apply bg-none;
  }
  
  /* responsive styles */

}