section.services {
  @apply relative
         bg-brandLight
         text-brandDark
         pt-48
         pb-48;

  &:before {
    @apply bg-[url(../img/separators/triangle-asymmetrical.svg)]
           bg-no-repeat
           bg-center
           content-[""]
           absolute
           w-full
           top-0
           left-0
           h-[120px];
  }

  &:after {
    @apply bg-[url(../img/separators/triangle-negative.svg)]
           bg-no-repeat
           bg-center
           content-[""]
           absolute
           w-full
           bottom-0
           left-0
           h-[120px];
  }

  .card {
    /* default styles */
    @apply flex
           flex-col
           max-w-full
           items-center
           bg-brandLighter
           rounded-lg
           shadow
           hover:shadow-lg
           transition-shadow ease-in-out duration-100;
    
    /* responsive styles */
    @apply p-6
           sm:p-10;
    @apply gap-5
           sm:gap-10
           lg:gap-20;
    @apply text-center
           md:text-left;

    @apply md:flex-row;

    >img {
      @apply order-first
             lg:order-none;
      @apply w-[80%]
             sm:w-[65%]
             md:w-[50%]
             lg:w-[400px]
             xl:w-[100%]
             mx-auto;
    }
  }
}