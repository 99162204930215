@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Montserrat, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

[data-popper-arrow], [data-popper-arrow]:before {
  width: 8px;
  height: 8px;
  background: inherit;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  width: 9px;
  height: 9px;
  background: inherit;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before, [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

[role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-auto {
  height: auto;
}

.h-48 {
  height: 12rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-72 {
  width: 18rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.rounded-lg {
  border-radius: .5rem;
}

.p-2 {
  padding: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.uppercase {
  text-transform: uppercase;
}

.text-brandLighter {
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
}

h1 {
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.25;
}

@media (min-width: 768px) {
  h1 {
    line-height: 1.5;
  }
}

h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

h2 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

p {
  letter-spacing: .025em;
  margin-bottom: .75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

p.h1-subheading {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  p.h1-subheading {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  p.h1-subheading {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

p.h2-subheading {
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  p.h2-subheading {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

label {
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

button.btn, [type="button"].btn, [type="reset"].btn, [type="submit"].btn {
  --tw-bg-opacity: 1;
  background-color: rgb(58 94 126 / var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
  border-radius: .5rem;
  align-items: center;
  padding: .625rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

button.btn:hover, [type="button"].btn:hover, [type="reset"].btn:hover, [type="submit"].btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 86 113 / var(--tw-bg-opacity));
}

button.btn.primary, [type="button"].btn.primary, [type="reset"].btn.primary, [type="submit"].btn.primary {
  --tw-bg-opacity: 1;
  background-color: rgb(223 59 87 / var(--tw-bg-opacity));
}

button.btn.primary:hover, [type="button"].btn.primary:hover, [type="reset"].btn.primary:hover, [type="submit"].btn.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(211 52 79 / var(--tw-bg-opacity));
}

button.btn.with-arrow:after, [type="button"].btn.with-arrow:after, [type="reset"].btn.with-arrow:after, [type="submit"].btn.with-arrow:after {
  content: "";
  width: 18px;
  height: 7.5px;
  background: url("arrow.9614628c.svg") center no-repeat;
  margin-left: 15px;
}

button.btn.with-arrow:hover:after, [type="button"].btn.with-arrow:hover:after, [type="reset"].btn.with-arrow:hover:after, [type="submit"].btn.with-arrow:hover:after {
  animation: buttonArrow .7s ease-in-out;
}

button.btn.with-arrow.reverse:after, button.btn.with-arrow.reverse:hover:after, [type="button"].btn.with-arrow.reverse:after, [type="button"].btn.with-arrow.reverse:hover:after, [type="reset"].btn.with-arrow.reverse:after, [type="reset"].btn.with-arrow.reverse:hover:after, [type="submit"].btn.with-arrow.reverse:after, [type="submit"].btn.with-arrow.reverse:hover:after {
  content: none;
}

button.btn.with-arrow.reverse:before, [type="button"].btn.with-arrow.reverse:before, [type="reset"].btn.with-arrow.reverse:before, [type="submit"].btn.with-arrow.reverse:before {
  content: "";
  width: 18px;
  height: 7.5px;
  background: url("arrow.9614628c.svg") center no-repeat;
  margin-right: 15px;
  transform: rotate(180deg);
}

button.btn.with-arrow.reverse:hover:before, [type="button"].btn.with-arrow.reverse:hover:before, [type="reset"].btn.with-arrow.reverse:hover:before, [type="submit"].btn.with-arrow.reverse:hover:before {
  animation: buttonArrowReverse .7s ease-in-out;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(58 94 126 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / var(--tw-text-opacity));
  background-color: #0000;
  border-radius: .25rem;
  padding: .625rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(58 94 126 / var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(58 94 126 / var(--tw-ring-opacity));
  background: #ffffff0d;
}

@keyframes buttonArrow {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(5px);
  }

  50% {
    transform: translate(0);
  }

  75% {
    transform: translate(5px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes buttonArrowReverse {
  0% {
    transform: rotate(180deg)translate(0);
  }

  25% {
    transform: rotate(180deg)translate(5px);
  }

  50% {
    transform: rotate(180deg)translate(0);
  }

  75% {
    transform: rotate(180deg)translate(5px);
  }

  100% {
    transform: rotate(180deg)translate(0);
  }
}

.tooltip.responsive-helper span > .tooltip-arrow:before, [role="tooltip"].responsive-helper span > [data-popper-arrow]:before, [role="tooltip"].responsive-helper span > [data-popper-arrow]:after {
  visibility: hidden;
}

.responsive-helper span {
  visibility: hidden;
  height: 45px;
  width: 45px;
  --tw-bg-opacity: 1;
  background-color: rgb(223 59 87 / var(--tw-bg-opacity));
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

@media (min-width: 640px) {
  .responsive-helper span._sm {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .responsive-helper span._md {
    visibility: visible;
  }
}

@media (min-width: 1024px) {
  .responsive-helper span._lg {
    visibility: visible;
  }
}

@media (min-width: 1280px) {
  .responsive-helper span._xl {
    visibility: visible;
  }
}

@media (min-width: 1536px) {
  .responsive-helper span._2xl {
    visibility: visible;
  }
}

header nav {
  z-index: 20;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(38 42 44 / var(--tw-bg-opacity));
  vertical-align: middle;
  --tw-shadow: 0 2px 5px 0px #ffffff14;
  --tw-shadow-colored: 0 2px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #393e4166;
  --tw-shadow: var(--tw-shadow-colored);
  border-radius: 0;
  padding: .25rem 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 640px) {
  header nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

header nav li a:after {
  content: "";
  width: 100%;
  height: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(223 59 87 / var(--tw-bg-opacity));
  opacity: 0;
  transition: opacity .3s, transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

header nav li a:hover:after, header nav li a:focus:after {
  opacity: 1;
  transform: translate3d(0, .2em, 0);
}

header nav li a {
  padding-bottom: .25rem;
  display: block;
  position: relative;
  overflow: hidden;
}

header nav li a:after {
  opacity: 1;
  transform-origin: center;
  transform: scale(0);
}

header nav li a:hover:after, header nav li a:focus:after {
  transform: scale(1);
}

footer {
  border-color: #393e4166;
  border-top-width: 2px;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

@media (min-width: 640px) {
  footer {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

footer ul.social-links {
  gap: .5rem;
  list-style-type: none;
  display: flex;
}

footer ul.social-links a {
  opacity: .6;
}

footer ul.social-links a:hover {
  opacity: .8;
}

footer ul.social-links a .social-icon {
  fill: #d6d6d6;
}

section.hero {
  text-align: center;
  padding-top: 9rem;
  padding-bottom: 7rem;
  position: relative;
}

@media (min-width: 768px) {
  section.hero {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
}

section.hero.hero-main {
  background-image: url("hero-bg.e7ce218c.png");
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

section.hero.hero-main:after {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #262a2c;
  --tw-gradient-to: #262a2c00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  height: 100px;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  position: absolute;
  bottom: 0;
  left: 0;
}

section.hero h1 {
  text-transform: uppercase;
  letter-spacing: .025em;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}

@media (min-width: 768px) {
  section.hero h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

section.hero p {
  letter-spacing: .05em;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (min-width: 768px) {
  section.hero p {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

section.hero a[type="button"] {
  padding: 1rem 2rem;
}

section.about {
  --tw-bg-opacity: 1;
  background-color: rgb(214 214 214 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 42 44 / var(--tw-text-opacity));
  padding-top: 7rem;
  padding-bottom: 12rem;
  position: relative;
}

section.about:before {
  height: 120px;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("about-top.18f4fe7f.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

section.about:after {
  height: 120px;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("about-bottom.9cb16722.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -2px;
  left: 0;
}

section.about h2 span {
  text-transform: uppercase;
  color: #393e4166;
  --tw-blur: blur(7px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
}

@media (min-width: 768px) {
  section.about h2 span {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    font-size: 140px;
  }
}

@media (min-width: 1024px) {
  section.about h2 span {
    font-size: 200px;
  }
}

section.about h2 img {
  margin-top: -40px;
}

@media (min-width: 768px) {
  section.about h2 img {
    margin-top: -80px;
  }
}

section.about p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
}

section.cta {
  padding-top: 5rem;
  padding-bottom: 4rem;
}

@media (min-width: 1024px) {
  section.cta {
    padding-top: 8rem;
    padding-bottom: 7rem;
  }
}

section.cta .idea {
  position: relative;
}

section.cta .idea:before {
  height: 160px;
  width: 170px;
  --tw-translate-x: -45px;
  --tw-translate-y: -20px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-content: "";
  content: var(--tw-content);
  background: url("idea.72aa59ff.svg") center no-repeat;
  margin: auto;
  display: block;
}

@media (min-width: 1024px) {
  section.cta .idea:before {
    --tw-translate-x: 0px;
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    position: absolute;
    top: -120px;
    left: -160px;
  }
}

section.cta h1 {
  text-transform: uppercase;
  margin-bottom: .25rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

@media (min-width: 768px) {
  section.cta h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

section.cta p {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  section.cta p {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

section.services {
  --tw-bg-opacity: 1;
  background-color: rgb(214 214 214 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 42 44 / var(--tw-text-opacity));
  padding-top: 12rem;
  padding-bottom: 12rem;
  position: relative;
}

section.services:before {
  height: 120px;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("triangle-asymmetrical.bd98a5ee.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

section.services:after {
  height: 120px;
  width: 100%;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("triangle-negative.ed31aa46.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
}

section.services .card {
  max-width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  align-items: center;
  transition-property: box-shadow;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

section.services .card:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

section.services .card {
  padding: 1.5rem;
}

@media (min-width: 640px) {
  section.services .card {
    padding: 2.5rem;
  }
}

section.services .card {
  gap: 1.25rem;
}

@media (min-width: 640px) {
  section.services .card {
    gap: 2.5rem;
  }
}

@media (min-width: 1024px) {
  section.services .card {
    gap: 5rem;
  }
}

section.services .card {
  text-align: center;
}

@media (min-width: 768px) {
  section.services .card {
    text-align: left;
    flex-direction: row;
  }
}

section.services .card > img {
  order: -9999;
}

@media (min-width: 1024px) {
  section.services .card > img {
    order: 0;
  }
}

section.services .card > img {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  section.services .card > img {
    width: 65%;
  }
}

@media (min-width: 768px) {
  section.services .card > img {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  section.services .card > img {
    width: 400px;
  }
}

@media (min-width: 1280px) {
  section.services .card > img {
    width: 100%;
  }
}

section.contact {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

section.not-found {
  height: calc(100vh - 4rem);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

section.not-found.hero.hero-main {
  background-image: none;
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(223 59 87 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(38 42 44 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(239 239 239 / var(--tw-text-opacity));
}

.hover\:bg-brandDarkOpacity:hover {
  background-color: #393e4166;
}

.hover\:text-brandLight:hover {
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-brandDarkOpacity:focus {
  --tw-ring-color: #393e4166;
}

@media (min-width: 640px) {
  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:h-96 {
    height: 24rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .md\:text-left {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:text-left {
    text-align: left;
  }
}

/*# sourceMappingURL=404.16d91a2d.css.map */
