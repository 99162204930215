section.about {
  @apply bg-brandLight
         text-brandDark
         relative
         pt-28
         pb-48;

  &:before {
    @apply bg-[url(../img/separators/about-top.svg)]
           bg-no-repeat
           bg-center
           content-[""]
           absolute
           w-full
           top-0
           left-0
           h-[120px];
  }

  &:after {
    @apply bg-[url(../img/separators/about-bottom.svg)]
           bg-no-repeat
           bg-center
           content-[""]
           absolute
           w-full
           bottom-[-2px]
           left-0
           h-[120px];
  }

  h2 span {
    /* default styles */
    @apply text-brandDarkOpacity
           uppercase
           font-medium
           leading-none;
    /* responsive styles */
    @apply text-[80px]
           blur-[7px];
    @apply md:text-[140px]
           md:blur-md;
    @apply lg:text-[200px];
  }

  h2 img {
    /* responsive styles */
    @apply mt-[-40px];
    @apply md:mt-[-80px];
  }

  p {
    @apply text-2xl
           font-light
           leading-normal;
  }
}
