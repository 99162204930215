h1 {
  /* default styles */
  @apply font-semibold
         leading-tight
         md:leading-normal
         uppercase
         mb-2;

  /* responsive styles */
  @apply text-3xl
         md:text-5xl
         lg:text-5xl;
}

h2 {
  @apply 
         font-semibold
         leading-normal
         uppercase
         mb-0;
  
  /* responsive styles */
  @apply text-2xl
         md:text-4xl;
}

p {
  @apply font-light
         text-base
         tracking-wide
         mb-3;

  &.h1-subheading {
    /* default styles */
    @apply font-light
           leading-normal;

    /* responsive styles */
    @apply text-xl;
    @apply md:text-2xl;
    @apply lg:text-3xl;
  }

  &.h2-subheading {
    @apply font-light
           leading-normal
           mb-5;

    /* responsive styles */
    @apply text-lg
           md:text-2xl;
  }
}