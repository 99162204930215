section.hero {
  /* default styles */
  @apply relative
         text-center;
  
  /* responsive styles */
  @apply pt-36
         pb-28;
  @apply md:pt-48
         md:pb-40;

  &.hero-main {
    @apply bg-[url(../img/hero-bg.png)]
           bg-no-repeat
           bg-fixed
           bg-top;

    &:after {
      @apply bg-gradient-to-t from-brandDark;
      @apply content-[""]
             absolute
             w-full
             bottom-0
             left-0
             h-[100px];
    }
  }
  
  h1 {
    /* default styles */
    @apply font-bold
           uppercase
           tracking-wide
           mb-4;

    /* responsive styles */
    @apply text-5xl;
    @apply md:text-6xl;
  }

  p {
    /* default styles */
    @apply font-light
           tracking-wider
           leading-tight
           mb-8;

    /* responsive styles */
    @apply text-2xl;
    @apply md:text-4xl;
  }

  a[type="button"] {
    @apply px-8
           py-4;
  }
}