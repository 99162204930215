@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './components/typography.scss';
@import './components/forms.scss';
@import './components/responsive-helper.scss';
@import './sections/header.scss';
@import './sections/footer.scss';
@import './sections/hero.scss';
@import './sections/about.scss';
@import './sections/cta.scss';
@import './sections/services.scss';
@import './sections/contact.scss';
@import './sections/not-found.scss';

::selection {
  @apply bg-brandPrimary
         text-white;
}

body {
  @apply bg-brandDark
         text-brandLighter;
}