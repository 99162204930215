label {
  @apply block
  mb-2
  text-sm
  text-brandLighter;
}

button, [type="button"], [type="reset"], [type="submit"] {
  &.btn {
    @apply text-brandLighter
    bg-brandSecondary
    hover:bg-brandSecondaryHover
    text-base
    font-medium
    uppercase
    tracking-wider
    rounded-lg
    inline-flex
    items-center
    px-5
    py-2.5
    transition;
    
    &.primary {
      @apply bg-brandPrimary;
      @apply hover:bg-brandPrimaryHover;
    }
    
    &.with-arrow {
      &:after {
        content: '';
        background: url(../img/arrow.svg) no-repeat center center;
        width: 18px;
        height: 7.5px;
        margin-left: 15px;
      }
      
      &:hover:after {
        animation: buttonArrow 0.7s ease-in-out 0s 1 normal none;
      }

      &.reverse {
        &::after, &:hover:after {
          content: none;
        }
        &:before {
          transform: rotate(180deg);
          content: '';
          background: url(../img/arrow.svg) no-repeat center center;
          width: 18px;
          height: 7.5px;
          margin-right: 15px;
        }
        
        &:hover:before {
          animation: buttonArrowReverse 0.7s ease-in-out 0s 1 normal none;
        }
      }
    }
  }
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  @apply bg-transparent
  border-2
  text-brandLight
  border-brandSecondary
  focus:border-brandSecondary
  focus:ring-brandSecondary
  text-sm
  rounded
  block
  w-full
  p-2.5;
  
  &:focus {
    background: rgba(255, 255, 255, 0.05);
  }
}

@keyframes buttonArrow {
  0% {
    transform:  translate(0,0);
  }
  25% {
    transform:  translate(5px, 0);
  }
  50% {
    transform:  translate(0,0);
  }
  75% {
    transform:  translate(5px, 0);
  }
  100% {
    transform:  translate(0,0);
  }
}

@keyframes buttonArrowReverse {
  0% {
    transform:  rotate(180deg) translate(0,0);
  }
  25% {
    transform:  rotate(180deg) translate(5px, 0);
  }
  50% {
    transform:  rotate(180deg) translate(0,0);
  }
  75% {
    transform:  rotate(180deg) translate(5px, 0);
  }
  100% {
    transform:  rotate(180deg) translate(0,0);
  }
}
