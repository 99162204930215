footer {
  @apply py-1
         border-t-2
         border-brandDarkOpacity;

  @apply sm:py-2;

  ul.social-links {
    @apply list-none flex gap-2;

    a {
      @apply opacity-60 hover:opacity-80;

      .social-icon {
        @apply fill-brandLight;
      }
    }
  }
}