header {
  nav {
    @apply bg-brandDark
           px-0
           sm:px-4
           py-1
           rounded-none
           flex
           align-middle
           fixed
           w-full
           z-20
           top-0
           left-0
           shadow-sm-light
           shadow-brandDarkOpacity;

   li a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      @apply bg-brandPrimary;
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    li a:hover::after,
    li a:focus::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }

    li a {
      @apply pb-1
             overflow-hidden
             block
             relative;
    }

    li a::after {
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
    }

    li a:hover::after,
    li a:focus::after{
      transform: scale(1);
    }
  }
}